import React, { Component } from 'react';
import './App.scss';

class Platforms extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const url = this.props.url;
        let html =  '';
    
        if( url ) {
            html = <aside className="platform-link-section">
                    <a href={url} target="_blank">
                        <img className="platform-image" src={this.props.children} />
                    </a>
                </aside>;
        } else {
            html = '';
        }

        return (
            <span>{html}</span>
        );
    }
}
export default Platforms;