import React, { Component } from 'react';
import './App.css';


class NumberOfPodcasts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            podcasts: [],
            number: 0,
        }
        this.countPodcasts = this.countPodcasts.bind(this);
    }

    componentDidMount() {
        console.clear();
        fetch(this.props.endpoint)
            .then(response => response.json())
            .then(
                podcasts => this.setState(() => {
                    return { podcasts: podcasts.map(this.countPodcasts) };
                })
            );
    }

    countPodcasts() {
        this.setState(prevState => {
            return {number: prevState.number + 1}
         });
    }

    render() {
        return (
            
            <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-purecounter-end="14000" data-aos="fade-up" data-aos-delay="200">
            <div class="count-box">
                <i class="bi bi-headset"></i>
                <p>{this.state.number}<strong>Podcasts</strong></p>
            </div>
        </div>

        );
    }
}
export default NumberOfPodcasts;
