import React, { Component } from 'react';
import './App.scss';
import Platforms from './Platforms';
import apple from './images/apple.png';
import spotify from './images/spotify.png';
import google from './images/google.png';
import nycpn from './images/nycpn.png';

class Overlay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            css_class: 'podcast_overlay_show',
            count: 0,
            podcaster_links_url: this.props.permalink,
        };
        this.close = this.close.bind(this);
        this.fixDescription = this.fixDescription.bind(this);
    }

    close(e) {
        e.preventDefault();
    }

    fixDescription(content) {
        content = content.replace(/\[p\]/g, '');
        return content;
    }

    getPodcasterLinksUrl(link) {
        link = link.replace('https://nycpodcastnetwork.com/podcast/', '');
        return 'https://podcasterlinks.com/' + link;
    }

    formatName(name) {
        name = name.replace('&#038;', '&');
        name = name.replace('&#8217;', "'");
        name = name.replace('&#8211;', "'");
        return name;
    }

    render() {
        
        var new_podcaster_links_url = this.props.permalink;
        new_podcaster_links_url = new_podcaster_links_url.replace('podcast/','');
        new_podcaster_links_url = new_podcaster_links_url.replace('nycpodcastnetwork','podcasterlinks');
        console.log('NEW LINK2: ' + new_podcaster_links_url);
        
        return (
            <article className={this.state.css_class}>
                <a href="#" onClick={(event) => this.close(event)}></a>

                <h2 className="overlay-title">{this.props.podcast_name}</h2>
                <aside className="site-name">
                    On the Media Girl Podcast Network
                </aside>

                <a href={this.props.permalink}>
                    <img className="podcast-overlay-thumbnail" alt={this.props.podcast_name} src={this.props.thumbnail} /><br />
                </a>             

                <aside className="overlay-description">
                    <aside className="aside-header">
                        <h4>About This Podcast</h4>
                    </aside>
                    <aside className="content">
                    {this.fixDescription(this.props.description)}
                    </aside>
                </aside>

                <aside className="aside-header">
                    <h4>Get Episodes</h4>
                </aside>

                <aside class="platform-links">
                    <Platforms url={this.props.permalink}>{nycpn}</Platforms>
                    <Platforms url={this.props.spotify}>{spotify}</Platforms>
                    <Platforms url={this.props.apple}>{apple}</Platforms>
                    <Platforms url={this.props.google}>{google}</Platforms>
                    <Platforms url={new_podcaster_links_url}>https://nycpodcastnetwork.com/wp-content/uploads/2021/07/follow-on-podcasterlinks.png</Platforms>
                </aside>
            </article>
        );
    }
}
export default Overlay;