import React, { Component } from 'react';
import './App.scss';
import Platforms from './Platforms';
import Overlay from './Overlay';
import loading_info from './images/loading.gif';

class Podcasts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            podcasts: [],
            loading_message: '♀ Loading Podcasts...',
            loading_message_css: 'loading-message',
            podcast_count_css_class: 'podcast-count-hide',
            overlay_section_css_class: 'overlay-section-hide',
            count: 0,
            overlay_class: 'podcast_overlay_hide',
            overlay_name: '',
            overlay_thumbnail: '',
            overlay_spotify: '',
            overlay_apple: '',
            overlay_google: '',
            overlay_permalink: '',
            overlay_description: '',
            close_class: "close-button-hidden",
        };
        this.mapPodcast = this.mapPodcast.bind(this);
        this.show_overlay = this.show_overlay.bind(this);
        this.close_overlay = this.close_overlay.bind(this);
    }

    componentDidMount() {
        console.clear();
        fetch(this.props.endpoint)
            .then(response => response.json())
            .then(podcasts => this.setState(() => {
                return { podcasts: podcasts.map(this.mapPodcast) };
            }))
            .then(

            );
    }

    mapPodcast(podcast) {

        this.setState(prevState => {
            return {
                count: prevState.count + 1,
                loading_message: '',
                loading_message_css: 'loading-message-none',
                podcast_count_css_class: 'podcast-count',
            }
        });

        return {
            id: podcast.id,
            name: podcast.title,
            link: podcast.link,
            description: podcast.description,
            date: podcast.release_date,
            thumbnail: podcast.image,
            spotify: podcast.spotify,
            apple: podcast.apple,
            google: podcast.google,
            permalink: podcast.link,
        }
    }

    formatDate(date) {
        var datetime = date.split('T');
        var newdate = datetime[0];
        var date_array = newdate.split('-');
        return parseInt(date_array[1]) + '/' + parseInt(date_array[2]) + '/' + parseInt(date_array[0]);
    }

    formatName(name) {
        name = name.replace('&#038;', '&');
        name = name.replace('&#8217;', "'");
        name = name.replace('&#8211;', "'");
        return name;
    }

    getPodcasterLinksUrl(link) {
        link = link.replace('https://nycpodcastnetwork.com/podcast/', '');
        return 'https://podcasterlinks.com/' + link;
    }

    show_overlay(event, name, thumbnail, spotify, apple, google, permalink, description) {
        event.preventDefault();
        this.setState({
            overlay_section_css_class: 'overlay-section-show',
            overlay_name: name,
            overlay_thumbnail: thumbnail,
            overlay_spotify: spotify,
            overlay_apple: apple,
            overlay_google: google,
            overlay_permalink: permalink,
            overlay_description: description,
            close_class: 'close-button',
        });

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }

    close_overlay() {
        this.setState({
            overlay_section_css_class: 'overlay-section-hide',
            close_class: 'close-button-hidden',
        })
    }

    render() {

        var current_count = this.state.count;

        return (

            <div className="App" >
                <section className={this.state.overlay_section_css_class}>
                    <button onClick={this.close_overlay} className={this.state.close_class}>&times;</button>
                    <Overlay css_class={this.state.overlay_class} description={this.state.overlay_description} podcast_name={this.state.overlay_name} thumbnail={this.state.overlay_thumbnail} permalink={this.state.overlay_permalink} spotify={this.state.overlay_spotify} apple={this.state.overlay_apple} google={this.state.overlay_google} />
                </section>


                <header className="App-header" >
                    <section className="podcast-grid" >
                        <div className={this.state.loading_message_css} ><img src={loading_info}/><br/>Loading Podcasts</div> {
                            this.state.podcasts.map((podcast) =>
                                <div className="podcast" key={`podcast-${podcast.id}}`} >
                                    <p>
                                        <a class="podcast-link" onClick={(event) => this.show_overlay(event, podcast.name, podcast.thumbnail, podcast.spotify, podcast.apple, podcast.google, podcast.link, podcast.description)} rel="noreferrer" target="_blank" href="#" >
                                            <img alt={`podcast-${podcast.name}}`} src={podcast.thumbnail} />
                                            <h1>
                                                {this.formatName(podcast.name)}<br />
                                            </h1>
                                        </a>
                                    </p >
                                </div>
                            )
                        }

                    </section>
                    <div className={this.state.podcast_count_css_class}>{this.state.count} Podcasts</div>
                </header>
            </div>
        );
    }
}
export default Podcasts;