import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Podcasts from './Podcasts';
import NumberOfPodcasts from './NumberOfPodcasts';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Podcasts endpoint="https://nycpodcastnetwork.com/wp-json/podcasts/v2/podcasts/mediagirlnetwork/"/>
  </React.StrictMode>,
  document.getElementById('podcast-grid')
);

ReactDOM.render(
  <React.StrictMode>
    <NumberOfPodcasts endpoint="https://nycpodcastnetwork.com/wp-json/podcasts/v2/podcasts/mediagirlnetwork/"/>
  </React.StrictMode>,
  document.getElementById('podcast-metric')
);





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
